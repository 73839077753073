import { SingleSignOnType, useSingleSignOn } from '../../hooks/useSingleSignOn';
import { GoogleSignInButton } from './buttons/GoogleSignInButton';
import { LoginDivider } from './LoginDivider';
import { MicrosoftSignInButton } from './buttons/MicrosoftSignInButton';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { Alert, Divider } from 'antd';

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  padding-left: 8px;
`;

const SignInAlertContainer = styled.div`
  padding-bottom: 16px;
`;

export function SignInFailure() {
  const router = useRouter();

  if (!router.query.sso_error) {
    return null;
  }

  return (
    <SignInAlertContainer>
      <Alert
        type={'error'}
        message={router.query.sso_error || 'We were unable to log you in.'}
        description={router.query.sso_error_description}
      />
    </SignInAlertContainer>
  );
}

export function SingleSignOn({ hideDivider }: { hideDivider?: boolean }) {
  const auth = useSingleSignOn();

  return (
    <div>
      {!hideDivider && (
        <Divider>
          <span className="text-xs tracking-wide font-semibold text-gray-500">OR</span>
        </Divider>
      )}
      <SignInFailure />
      <div className="grid grid-cols-2 gap-4">
        <GoogleSignInButton
          onClick={() => {
            auth.authorize(SingleSignOnType.Google);
          }}
        />
        <MicrosoftSignInButton
          onClick={() => {
            auth.authorize(SingleSignOnType.Microsoft);
          }}
        />
      </div>
    </div>
  );
}
