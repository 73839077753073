import { memo } from 'react';
import { OAuthSignInButton } from './OAuthSignInButton';

const GoogleLogo = () => (
  <svg width="34" height="37" viewBox="0 0 34 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="34" height="37" rx="2" fill="white" />
    <g clipPath="url(#clip0_511:457)">
      <path
        d="M25.8087 19.2025C25.8087 18.61 25.7562 18.0475 25.6662 17.5H17.1912V20.8825H22.0437C21.8262 21.9925 21.1887 22.93 20.2437 23.5675V25.8175H23.1387C24.8337 24.25 25.8087 21.94 25.8087 19.2025Z"
        fill="#4285F4"
      />
      <path
        d="M17.1912 28.0003C19.6212 28.0003 21.6537 27.1903 23.1387 25.8178L20.2437 23.5678C19.4337 24.1078 18.4062 24.4378 17.1912 24.4378C14.8437 24.4378 12.8562 22.8553 12.1437 20.7178H9.15869V23.0353C10.6362 25.9753 13.6737 28.0003 17.1912 28.0003Z"
        fill="#34A853"
      />
      <path
        d="M12.1437 20.7173C11.9562 20.1773 11.8587 19.5998 11.8587 18.9998C11.8587 18.3998 11.9637 17.8223 12.1437 17.2823V14.9648H9.15866C8.54366 16.1798 8.19116 17.5448 8.19116 18.9998C8.19116 20.4548 8.54366 21.8198 9.15866 23.0348L12.1437 20.7173Z"
        fill="#FBBC05"
      />
      <path
        d="M17.1912 13.5625C18.5187 13.5625 19.7037 14.02 20.6412 14.9125L23.2062 12.3475C21.6537 10.8925 19.6212 10 17.1912 10C13.6737 10 10.6362 12.025 9.15869 14.965L12.1437 17.2825C12.8562 15.145 14.8437 13.5625 17.1912 13.5625Z"
        fill="#EA4335"
      />
    </g>
    <defs>
      <clipPath id="clip0_511:457">
        <rect width="18" height="18" fill="white" transform="translate(8 10)" />
      </clipPath>
    </defs>
  </svg>
);

const GoogleLogoMemoized = memo(GoogleLogo);

export function GoogleSignInButton({ onClick }: { onClick: () => any }) {
  return <OAuthSignInButton logo={<GoogleLogoMemoized />} name="Google" onClick={onClick} />;
}
