import { ReactNode } from 'react';

export function OAuthSignInButton({
  onClick,
  logo,
  name,
}: {
  onClick: () => any;
  logo: ReactNode;
  name: string;
}) {
  return (
    <button
      className="tw-reset appearance-none bg-transparent border shadow-sm rounded-md cursor-pointer hover:shadow transition-shadow duration-150 flex items-center gap-4 justify-center pr-6"
      onClick={onClick}
    >
      {logo}
      <span className="text-sm font-semibold font-sans">{name}</span>
    </button>
  );
}
