import { memo } from 'react';
import { OAuthSignInButton } from './OAuthSignInButton';

const MicrosoftLogo = () => (
  <svg width="34" height="37" viewBox="0 0 34 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="34" height="37" rx="2" fill="white" />
    <path d="M16.4737 8.5H7V17.9737H16.4737V8.5Z" fill="#F25022" />
    <path d="M16.4737 19.0264H7V28.5001H16.4737V19.0264Z" fill="#00A4EF" />
    <path d="M27.0001 8.5H17.5264V17.9737H27.0001V8.5Z" fill="#7FBA00" />
    <path d="M27.0001 19.0264H17.5264V28.5001H27.0001V19.0264Z" fill="#FFB900" />
  </svg>
);

const MicrosoftLogoMemoized = memo(MicrosoftLogo);

export function MicrosoftSignInButton({ onClick }: { onClick: () => any }) {
  return <OAuthSignInButton logo={<MicrosoftLogoMemoized />} name="Microsoft" onClick={onClick} />;
}
