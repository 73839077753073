import { useRef } from 'react';
import auth0 from 'auth0-js';
import { getClientConfig } from '../core/config/nextConfig';

export enum SingleSignOnType {
  Google = 'google-oauth2',
  Microsoft = 'solv-azure-ad',
}

export function useSingleSignOn() {
  const auth = useRef(
    new auth0.WebAuth({
      domain: getClientConfig('NEXT_PUBLIC_SSO_DOMAIN') ?? '',
      clientID: getClientConfig('NEXT_PUBLIC_SSO_CLIENT_ID') ?? '',
    })
  );

  return {
    authorize: (type: SingleSignOnType) => {
      auth.current.authorize({
        connection: type,
        responseType: 'id_token',
        redirectUri: `${window.location.origin}/sso/callback`,
      });
    },
  };
}
