import React from 'react';
import history from '../../core/history';

function isLeftClickEvent(event: React.MouseEvent) {
  return event.button === 0;
}

function isModifiedEvent(event: React.MouseEvent) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

const urlOptions = (to: string, hash: string) => ({
  pathname: to,
  hash,
});

interface LinkProps {
  to: string;
  hash?: string;
  className?: string;
  onClick?: React.MouseEventHandler;
}

const Link = ({
  to,
  hash = '',
  className,
  onClick,
  children,
  ...rest
}: React.PropsWithChildren<LinkProps>) => {
  const handleClick: React.MouseEventHandler = (event) => {
    let allowTransition = true;

    onClick?.(event);

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (event.defaultPrevented) {
      allowTransition = false;
    }

    event.preventDefault();

    if (allowTransition) {
      if (to) {
        history.push(urlOptions(to, hash));
      } else {
        history.push({
          // @ts-expect-error not sure what these were originally pointing too, but they don't exist
          pathname: event.currentTarget.pathname,
          // @ts-expect-error not sure what these were originally pointing too, but they don't exist
          search: event.currentTarget.search,
        });
      }
    }
  };

  return (
    <a
      className={className}
      href={history.createHref(urlOptions(to, hash))}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </a>
  );
};

export default Link;
